import CustomersDefault from '@components/pages/max/global/Customers/themed/default';

import clsx from 'clsx';

import styles from './SectionCustomers.module.css';

type Content = {
    sectionHeadline?: JSX.Element;
    customClass?: string;
    container: React.ReactElement;
};

export default function Customers({ sectionHeadline, customClass, container }: Content) {
    return (
        <CustomersDefault
            customClass={clsx(styles['section-customers'], customClass)}
            container={container}
            maxWidthAwards={'1030px'}
            sectionHeadline={sectionHeadline}
        />
    );
}
