import React from 'react';

import Trans from '@components/Trans';
import DeskoptBr from '@components/ui/DeskoptBr';

import styles from './SolidIntegrations.module.css';
import { ReactComponent as AnalyticsSVG } from './assets/1_analytics.svg';
import { ReactComponent as Teachable } from './assets/2_teachable.svg';
import { ReactComponent as WooCommerceSVG } from './assets/3_woocommerce.svg';
import { ReactComponent as ZapierSVG } from './assets/4_zapier.svg';
import { ReactComponent as Shopify } from './assets/5_shopify.svg';
import { ReactComponent as Magento } from './assets/6_magento.svg';
import { ReactComponent as GoogleAds } from './assets/7_googleads.svg';
import { ReactComponent as BingSVG } from './assets/8_bingads.svg';
import { ReactComponent as YandexVG } from './assets/9_yandexmetrica.svg';
import { ReactComponent as SlackSVG } from './assets/10_slack.svg';
import { ReactComponent as FBSVG } from './assets/11_facebook.svg';
import { ReactComponent as YTSVG } from './assets/12_youtube.svg';
import { ReactComponent as Dynamic365SVG } from './assets/13_dynamic365.svg';
import { ReactComponent as PrestaShopSVG } from './assets/14_prestashop.svg';
import { ReactComponent as PayPalSVG } from './assets/15_paypal.svg';
import { ReactComponent as StripeSVG } from './assets/16_stripe.svg';
import { ReactComponent as PayUSVG } from './assets/17_payu.svg';

const ns = 'pages/max/design2024/components/SolidIntegrations/index';

interface SolidIntegrationsProps {
    title?: JSX.Element;
    description?: JSX.Element;
}

const SolidIntegrations = ({ title, description }: SolidIntegrationsProps) => {
    const titleText = title ? (
        title
    ) : (
        <Trans
            i18nKey="sectionTitle"
            components={{
                br: <DeskoptBr />,
                strong: <strong />,
                b: <b />,
                p: <p />,
                span: <span />,
            }}
            ns={ns}
        />
    );
    const descriptionText = description ? (
        description
    ) : (
        <Trans
            i18nKey="sectionDescription"
            components={{
                br: <DeskoptBr />,
                strong: <strong />,
                b: <b />,
                p: <p />,
                span: <span />,
            }}
            ns={ns}
        />
    );
    return (
        <div className={styles.integrations}>
            <h2>{titleText}</h2>
            {descriptionText}
            <div className="wrapperLogoIntergrtion">
                <div className={styles['blok-flex']}>
                    <AnalyticsSVG width={'148.555px'} />
                    <Teachable width={'138px'} />
                    <WooCommerceSVG width={'157.555px'} />
                    <ZapierSVG width={'104.675px'} />
                    <Shopify width={'121.955px'} />
                    <Magento width={'134.26px'} />

                    <GoogleAds width={'190.3px'} />
                    <BingSVG width={'136.09px'} />
                    <YandexVG width={'134.9px'} />
                    <SlackSVG width={'115.115px'} />
                    <FBSVG width={'129.94px'} />
                    <YTSVG width={'120.32px'} />

                    <Dynamic365SVG width={'189.395px'} />
                    <PrestaShopSVG width={'173px'} />
                    <PayPalSVG width={'145.62px'} />
                    <StripeSVG width={'95.49px'} />
                    <PayUSVG width={'123.31px'} />
                </div>
                <div className={styles['blok-flex']}></div>
            </div>
        </div>
    );
};

export default SolidIntegrations;
