import React, { FunctionComponent, cloneElement } from 'react';

import SolidIntegrations from '@components/pages/max/design2024/components/SolidIntegrations';

import styles from './SectionSolidIntegrations.module.css';

const Content: FunctionComponent = () => {
    return <SolidIntegrations />;
};

interface SectionSolidIntegrationsProps {
    container: React.ReactElement;
}

export default function SectionSolidIntegrations({ container }: SectionSolidIntegrationsProps): JSX.Element {
    return (
        <section className={styles['section-solid-integrations']}>
            {cloneElement(container, {
                children: <Content />,
            })}
        </section>
    );
}
