import React from 'react';

import DefaultCustomers from '@components/pages/max/global/Customers';

import type { CustomersProps } from '../../../Customers';
import styles from './Customers.module.css';

const Customers = (props: CustomersProps) => {
    return (
        <DefaultCustomers
            {...props}
            themeStyles={styles}
        />
    );
};

export default Customers;
