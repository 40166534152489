import React from 'react';

import Heading from '@components/global/Heading';
import VwoTarget from '@components/preview/VwoTarget';
import DeskoptBr from '@components/ui/DeskoptBr';
import Underline from '@components/ui/Underline';

import clsx from 'clsx';

import styles from './Hero.module.css';

interface HeroProps {
    title?: JSX.Element | string;
    subtitle?: JSX.Element | string;
    button?: JSX.Element;
    image?: JSX.Element;
    customStyle?: Record<string, string>;
}

const Hero = ({ title, subtitle, button, image, customStyle }: HeroProps) => {
    return (
        <div className={clsx(styles.split, customStyle?.split)}>
            <article className={clsx(styles.desc, customStyle?.desc)}>
                {title && (
                    <VwoTarget
                        id="vwo-target-max-homepage-title"
                        components={[
                            {
                                name: 'mark',
                                component: <Underline variant="max-orange">placeholder</Underline>,
                            },
                            { name: 'br', component: <DeskoptBr /> },
                        ]}
                    >
                        <Heading variant="h1">{title}</Heading>
                    </VwoTarget>
                )}
                {subtitle && (
                    <VwoTarget id="vwo-target-max-homepage-subtitle">
                        <p>{subtitle}</p>
                    </VwoTarget>
                )}

                {button ? button : null}
            </article>
            {image && <div className={clsx(styles.picture, customStyle?.picture)}>{image}</div>}
        </div>
    );
};

export default Hero;
