import React, { FunctionComponent, cloneElement } from 'react';

import BookADemo from '@components/pages/max/design2024/components/BookAdemo';

import clsx from 'clsx';

import styles from './SectionBookADemo.module.css';

interface ContentProps {
    header?: JSX.Element | string | false;
}
const Content: React.FunctionComponent<ContentProps> = ({ header }) => {
    return <BookADemo header={header} />;
};

interface SectionBookADemoProps extends ContentProps {
    container: React.ReactElement;
    customClass?: string;
}
export default function SectionBookADemo({ container, customClass, header }: SectionBookADemoProps): JSX.Element {
    return (
        <section className={clsx(styles['book-a-demo'], customClass)}>
            {cloneElement(container, {
                children: <Content header={header} />,
            })}
        </section>
    );
}
