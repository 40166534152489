import React from 'react';

import DefaultMaxHeader from '@components/pages/max/MaxHeader';
import type { MaxHeaderProps } from '@components/pages/max/MaxHeader';

import styles from './MaxHeader.module.css';

const MaxHeader = (props: MaxHeaderProps) => {
    return (
        <DefaultMaxHeader
            {...props}
            themeStyles={styles}
            theme={'default'}
        />
    );
};

export default MaxHeader;
