import React from 'react';

import { useRouter } from 'next/router';

import Trans from '@components/Trans';
import DeskoptBr from '@components/ui/DeskoptBr';
import { Button } from '@components/ui/design2023/Button';

import { laravelRouteMaxBookADemo } from '@static_components/laravelLinks';

import styles from './BookADemo.module.css';

const ns = 'pages/max/design2024/components/BookADemo/index';

interface BookADemoProps {
    header?: JSX.Element | string | false;
    description?: JSX.Element | string | false;
    buttonLabel?: JSX.Element | string;
    arrow?: boolean;
}
//Ready for a personalised product demo?
const BookADemo = ({ header, description, buttonLabel, arrow = true }: BookADemoProps): JSX.Element => {
    const { locale } = useRouter();
    const headerText =
        header && header !== typeof 'boolean' ? (
            header
        ) : (
            <Trans
                i18nKey="sectionDefaultTitle"
                components={{
                    br: <DeskoptBr />,
                    strong: <strong />,
                    b: <b />,
                    p: <p />,
                    span: <span />,
                }}
                ns={ns}
            />
        );

    const descriptionText =
        description && description !== typeof 'boolean' ? (
            description
        ) : (
            <Trans
                i18nKey="sectionDefaultDescription"
                components={{
                    br: <DeskoptBr />,
                    strong: <strong />,
                    b: <b />,
                    p: <p />,
                }}
                ns={ns}
            />
        );
    const labelText =
        buttonLabel && buttonLabel !== typeof 'boolean' ? (
            buttonLabel
        ) : (
            <Trans
                i18nKey="buttonDefaultLabel"
                components={{
                    strong: <strong />,
                    b: <b />,
                }}
                ns={ns}
            />
        );

    return (
        <div className={styles.bookADemo}>
            {header !== typeof 'boolean' && <h2>{headerText}</h2>}
            <div className={styles.description}>{descriptionText !== typeof 'boolean' && descriptionText}</div>
            <div className={styles.action}>
                <Button
                    color="max-orange"
                    size="large"
                    label={labelText}
                    arrow={arrow}
                    href={`${laravelRouteMaxBookADemo(locale)}`}
                />
            </div>
        </div>
    );
};

export default BookADemo;
